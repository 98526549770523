export interface SourceApiResponse {
    id: string,
    name: string,
    SourceFolderId: string | null,
    updatedAt: string,
    createdAt: string,
    source: SourceDto
    version: SourceVersionDto
    details: WebsiteSourceDetails
}

export enum SourceType {
    WEBPAGE = 'WEBPAGE',
    DATASET = 'DATASET',
}

export interface SourceDto {
    id: string
    title: string
    type: SourceType
    createdAt: string
    updatedAt: string
    SourceFolderId: string | null,
}


export enum SourceVersionUpdateType {
    INITIAL_VERSION = 'INITIAL_VERSION',
    AUTO_UPDATE = 'AUTO_UPDATE',
    MANUAL_UPDATE = 'MANUAL_UPDATE',
}

export interface SourceVersionDto {
    id: string;
    createdAt: string;
    updateType: SourceVersionUpdateType;
    updatedAt: string;
    sourceId: string;
}

export interface WebsiteSourceDetails {
    id: string;
    azureVectorFileId: string;
    status: 'COMPLETED' | 'PENDING' | 'FAILED';
    title: string;
    url: string;
    description: string;
    tags: string[];
    interpretedType: string;
    image: string;
    createdAt: string;
    vectorStoreId: string;
    sourceVersionId: string;
}